import './App.css';
import './input.css';
import './output.css';
import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import DashboardView from './views/DashboardView/DashboardView';
import HomeView from './views/HomeView/HomeView';
import PreOrderView from './views/PreOrderView/PreOrderView';
import ContactView from './views/ContactView/ContactView';
import RegisterView from './views/RegisterView/RegisterView';
// import EmailEditorView from './views/EmailEditorView';
import PortalView from './views/PortaalViews/PortaalView/PortaalView';
import { NextUIProvider } from "@nextui-org/react";
import PaymentView from './views/PaymentView/PaymentView';
import GeneralTermsView from './views/GeneralTermsView/GeneralTermsView';
import PrivacyPolicyView from './views/PrivacyPolicyView/PrivacyPolicyView';
import PaymentCanceledView from './views/PaymentCanceledView/PaymentCanceledView';
import PayedPaymentView from './views/PayedPaymentView/PayedPaymentView';
import FAQView from './views/FAQView/FAQView';
import ForgotPasswordView from './views/ForgotPasswordView/ForgotPasswordView';
import UnsubscribeEmailView from './views/UnsubscribeEmailView/UnsubscribeEmailView';
import { HelmetProvider } from 'react-helmet-async';
import LoginView from './views/LoginView/LoginView';
import { HistoryProvider } from './Providers/HistoryProvider';


function App() {
  return (
    <HelmetProvider>
      <NextUIProvider>
        <Router>
          <HistoryProvider>
            <Routes>
              {/* <Route exact path="/" element={<DashboardView/>} /> */}
              <Route path="/" element={<HomeView />} />
              <Route path="/#features" element={<HomeView />} />
              <Route path="/#pricing" element={<HomeView />} />
              <Route path="/contact" element={<ContactView />} />
              <Route path="/register" element={<RegisterView />} />
              <Route path="/pre-order" element={<PreOrderView />} />
              <Route path="/faq" element={<FAQView />} />
              <Route path="/portaal/*" element={<PortalView />} />
              <Route path="/betalen" element={<PaymentView />} />
              <Route path="/login" element={<LoginView />} />
              <Route path="/betaling-geannuleerd" element={<PaymentCanceledView />} />
              <Route path="/betaald" element={<PayedPaymentView />} />
              <Route path="/algemene-voorwaarden" element={<GeneralTermsView />} />
              <Route path="/privacy-overeenkomst" element={<PrivacyPolicyView />} />
              <Route path="/wachtwoord-vergeten" element={<ForgotPasswordView />} />
              <Route path="/unsubscribe" element={<UnsubscribeEmailView />} />
            </Routes>
          </HistoryProvider>
        </Router>
      </NextUIProvider>
    </HelmetProvider>
  );
}

export default App;
